/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.sidebar-header .brand {
  color: #fff;
  text-align: left;
  padding-left: 25px;
  line-height: 70px;
  font-size: 16px; }
  @media (max-width: 767px) {
    .sidebar-header .brand {
      line-height: 50px;
      font-size: 16px; } }
