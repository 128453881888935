/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.header .header-block-collapse {
  padding-right: 5px; }
  @media (max-width: 767px) {
    .header .header-block-collapse {
      width: 155px; } }
  .header .header-block-collapse .collapse-btn {
    background: none;
    border: none;
    box-shadow: none;
    color: #85CE36;
    font-size: 24px;
    line-height: 40px;
    border-radius: 0;
    outline: none;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: initial; }
