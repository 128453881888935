/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.flex-row {
  display: flex;
  flex-direction: row; }

.flex-col {
  display: flex;
  flex-direction: column; }

.centralize-y {
  display: flex;
  align-items: center; }
