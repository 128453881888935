/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.pagination {
  margin-top: 0; }
  .pagination .page-item .page-link {
    color: #85CE36; }
  .pagination .page-item.active .page-link,
  .pagination .page-item.active .page-link:focus,
  .pagination .page-item.active .page-link:hover {
    color: #fff;
    border-color: #85CE36;
    background-color: #85CE36; }
