/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.header .header-block-nav .notifications {
  font-size: 16px; }
  .header .header-block-nav .notifications a {
    padding-right: 10px; }
  .header .header-block-nav .notifications .counter {
    font-weight: bold;
    font-size: 14px;
    position: relative;
    top: -3px;
    left: -2px; }
  .header .header-block-nav .notifications.new .counter {
    color: #85CE36;
    font-weight: bold; }
  @media (max-width: 767px) {
    .header .header-block-nav .notifications {
      position: static; } }

.header .header-block-nav .notifications-dropdown-menu {
  white-space: normal;
  left: auto;
  right: 0;
  min-width: 350px; }
  .header .header-block-nav .notifications-dropdown-menu:before {
    position: absolute;
    right: 20px;
    bottom: 100%;
    margin-right: -1px; }
  .header .header-block-nav .notifications-dropdown-menu:after {
    position: absolute;
    right: 20px;
    bottom: 100%; }
  .header .header-block-nav .notifications-dropdown-menu .notifications-container .notification-item {
    border-bottom: 1px solid rgba(126, 142, 159, 0.1);
    padding: 5px; }
    .header .header-block-nav .notifications-dropdown-menu .notifications-container .notification-item:hover {
      background-color: #f5f5f5; }
  .header .header-block-nav .notifications-dropdown-menu .notifications-container .img-col {
    display: table-cell;
    padding: 5px; }
  .header .header-block-nav .notifications-dropdown-menu .notifications-container .body-col {
    padding: 5px;
    display: table-cell; }
  .header .header-block-nav .notifications-dropdown-menu .notifications-container .img {
    width: 40px;
    height: 40px;
    border-radius: 3px;
    vertical-align: top;
    display: inline-block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .header .header-block-nav .notifications-dropdown-menu .notifications-container p {
    color: #4f5f6f;
    display: inline-block;
    line-height: 18px;
    font-size: 13px;
    margin: 0;
    vertical-align: top; }
    .header .header-block-nav .notifications-dropdown-menu .notifications-container p .accent {
      font-weight: bold; }
  .header .header-block-nav .notifications-dropdown-menu footer {
    text-align: center; }
    .header .header-block-nav .notifications-dropdown-menu footer a {
      color: #373a3c;
      transition: none; }
      .header .header-block-nav .notifications-dropdown-menu footer a:hover {
        background-color: #f5f5f5;
        color: #85CE36; }
  @media (max-width: 767px) {
    .header .header-block-nav .notifications-dropdown-menu {
      min-width: 100px;
      width: 100%;
      margin-top: 5px; }
      .header .header-block-nav .notifications-dropdown-menu:before, .header .header-block-nav .notifications-dropdown-menu:after {
        right: 107px; } }
