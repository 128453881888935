/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
#nprogress .bar {
  background: #85CE36 !important; }
  #nprogress .bar .peg {
    box-shadow: 0 0 10px #85CE36, 0 0 5px #85CE36; }

#nprogress .spinner {
  top: 25px !important;
  right: 23px !important; }
  #nprogress .spinner .spinner-icon {
    border-top-color: #85CE36 !important;
    border-left-color: #85CE36 !important; }
