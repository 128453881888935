/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.footer {
  background-color: #fff;
  position: absolute;
  left: 230px;
  right: 0;
  bottom: 0;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .footer-fixed .footer {
    position: fixed; }
  .footer .footer-block {
    vertical-align: middle;
    margin-left: 20px;
    margin-right: 20px; }
  .footer .footer-github-btn {
    vertical-align: middle; }
  @media (max-width: 991px) {
    .footer {
      left: 0; } }
  .footer .author > ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .footer .author > ul li {
      display: inline-block; }
      .footer .author > ul li:after {
        content: "|"; }
      .footer .author > ul li:last-child:after {
        content: ""; }
      @media (max-width: 991px) {
        .footer .author > ul li {
          display: block;
          text-align: right; }
          .footer .author > ul li:after {
            content: ""; } }
    @media (max-width: 991px) {
      .footer .author > ul {
        display: block; } }
    @media (max-width: 767px) {
      .footer .author > ul {
        display: none; } }
