/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.error-card {
  width: 410px;
  min-height: 330px;
  margin: 60px auto; }
  .error-card .error-title {
    font-size: 150px;
    line-height: 150px;
    font-weight: 700;
    color: #252932;
    text-align: center;
    text-shadow: rgba(61, 61, 61, 0.3) 0.5px 0.5px, rgba(61, 61, 61, 0.2) 1px 1px, rgba(61, 61, 61, 0.3) 1.5px 1.5px; }
  .error-card .error-sub-title {
    font-weight: 100;
    text-align: center; }
  .error-card .error-container {
    text-align: center;
    visibility: hidden; }
    .error-card .error-container.visible {
      visibility: visible; }
  .error-card.global {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    margin: 0; }
    .error-card.global .error-title {
      color: #ffffff; }
    .error-card.global .error-sub-title,
    .error-card.global .error-container {
      color: #ffffff; }

@media (min-width: 768px) and (max-width: 991px) {
  .error-card {
    width: 50%; }
    .error-card.global {
      position: relative;
      top: 25%;
      left: 0;
      transform: inherit;
      margin: 40px auto; } }

@media (max-width: 767px) {
  .error-card {
    width: 90%; }
    .error-card.global {
      position: relative;
      top: 25%;
      left: 0;
      transform: inherit;
      margin: 40px auto; } }
