/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.images-container::after {
  content: "";
  display: table;
  clear: both; }

.images-container .image-container {
  float: left;
  padding: 3px;
  margin-right: 10px;
  margin-bottom: 35px;
  position: relative;
  border: 1px solid #e6eaee;
  overflow: hidden; }
  .images-container .image-container.active {
    border-color: #85CE36; }
  .images-container .image-container:hover .controls {
    bottom: 0;
    opacity: 1; }

.images-container .controls {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  bottom: -35px;
  text-align: center;
  height: 35px;
  font-size: 24px;
  transition: bottom 0.2s ease, opacity 0.2s ease;
  background-color: #fff; }
  .images-container .controls::after {
    content: "";
    display: table;
    clear: both; }
  .images-container .controls .control-btn {
    display: inline-block;
    color: #4f5f6f;
    cursor: pointer;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.3s ease; }
    .images-container .controls .control-btn:hover {
      opacity: 1; }
    .images-container .controls .control-btn.move {
      cursor: move; }
    .images-container .controls .control-btn.star {
      color: #FFB300; }
      .images-container .controls .control-btn.star i:before {
        content: "\f006"; }
      .images-container .controls .control-btn.star.active i:before {
        content: "\f005"; }
    .images-container .controls .control-btn.remove {
      color: #FF4444; }

.images-container .image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 130px;
  height: 135px;
  line-height: 135px;
  text-align: center; }

.images-container .image-container.main {
  border-color: #FFB300; }

.images-container .image-container.new {
  opacity: 0.6;
  transition: opacity 0.3s ease;
  border-style: dashed;
  border: 1px #85CE36 solid;
  color: #85CE36; }
  .images-container .image-container.new .image {
    font-size: 2.5rem; }
  .images-container .image-container.new:hover {
    opacity: 1; }
