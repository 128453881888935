/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
input,
textarea {
  outline: none; }

.form-group .row {
  margin-left: -10px;
  margin-right: -10px; }
  .form-group .row [class^='col'] {
    padding-left: 10px;
    padding-right: 10px; }

.form-group.has-error span.has-error {
  color: #FF4444;
  font-size: 13px;
  display: block !important; }

.form-group.has-error .form-control-feedback {
  color: #FF4444; }

.form-group.has-warning span.has-warning {
  color: #fe974b;
  font-size: 13px;
  display: block !important; }

.form-group.has-warning .form-control-feedback {
  color: #fe974b; }

.form-group.has-success span.has-success {
  color: #4bcf99;
  font-size: 13px;
  display: block !important; }

.form-group.has-success .form-control-feedback {
  color: #4bcf99; }

/* Input groups */
.input-group {
  margin-bottom: 10px; }
  .input-group .form-control {
    padding-left: 5px; }
  .input-group span.input-group-addon {
    font-style: italic;
    border: none;
    border-radius: 0;
    border: none;
    background-color: #d7dde4;
    transition: background-color ease-in-out 15s, color ease-in-out .15s; }
    .input-group span.input-group-addon.focus {
      background-color: #85CE36;
      color: #ffffff; }

label,
.control-label {
  font-weight: 600; }

.form-control.underlined {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 1px solid #d7dde4; }
  .form-control.underlined.indented {
    padding: .375rem .75rem; }
  .has-error .form-control.underlined:focus,
  .has-warning .form-control.underlined:focus,
  .has-success .form-control.underlined:focus, .form-control.underlined:focus {
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #85CE36; }
  .has-error .form-control.underlined {
    box-shadow: none;
    border-color: #FF4444; }
  .has-warning .form-control.underlined {
    box-shadow: none;
    border-color: #fe974b; }
  .has-success .form-control.underlined {
    box-shadow: none;
    border-color: #4bcf99; }

.form-control.boxed {
  border-radius: 0;
  box-shadow: none; }
  .form-control.boxed:focus {
    border: 1px solid #85CE36; }

.radio,
.checkbox {
  display: none; }
  .radio + span,
  .checkbox + span {
    padding: 0;
    padding-right: 10px; }
  .radio + span:before,
  .checkbox + span:before {
    font-family: FontAwesome;
    font-size: 21px;
    display: inline-block;
    vertical-align: middle;
    letter-spacing: 10px;
    color: #c8d0da; }
  .radio:checked + span:before,
  .checkbox:checked + span:before {
    color: #85CE36; }
  .radio:disabled + span:before,
  .checkbox:disabled + span:before {
    opacity: 0.5;
    cursor: not-allowed; }
  .radio:checked:disabled + span:before,
  .checkbox:checked:disabled + span:before {
    color: #c8d0da; }

.checkbox + span:before {
  content: "\f0c8"; }

.checkbox:checked + span:before {
  content: "\f14a"; }

.checkbox.rounded + span:before {
  content: "\f111"; }

.checkbox.rounded:checked + span:before {
  content: "\f058"; }

.radio + span:before {
  content: "\f111"; }

.radio:checked + span:before {
  content: "\f192"; }

.radio.squared + span:before {
  content: "\f0c8"; }

.radio.squared:checked + span:before {
  content: "\f14a"; }

.form-control::-webkit-input-placeholder {
  font-style: italic;
  color: #c8d0da; }

.form-control:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
  color: #d7dde4; }

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  color: #d7dde4; }

.form-control:-ms-input-placeholder {
  font-style: italic;
  color: #d7dde4; }
