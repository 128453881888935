/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.header .header-block-search {
  margin-right: auto;
  padding-left: 20px; }
  @media (max-width: 767px) {
    .header .header-block-search {
      padding-left: 10px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .header .header-block-search {
    padding-left: 20px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .header .header-block-search {
    padding-left: 20px; } }
  @media (min-width: 1200px) {
    .header .header-block-search {
      padding-left: 35px; } }
  .header .header-block-search > form {
    float: right; }
    @media (max-width: 767px) {
      .header .header-block-search > form {
        padding-left: 0; } }
  .header .header-block-search .input-container {
    position: relative;
    color: #7e8e9f; }
    .header .header-block-search .input-container i {
      position: absolute;
      pointer-events: none;
      display: block;
      height: 40px;
      line-height: 40px;
      left: 0; }
    .header .header-block-search .input-container input {
      background-color: transparent;
      border: none;
      padding-left: 25px;
      height: 40px;
      max-width: 150px; }
      @media (max-width: 767px) {
        .header .header-block-search .input-container input {
          max-width: 140px; } }
    .header .header-block-search .input-container input:focus + .underline {
      transform: scaleX(1); }
