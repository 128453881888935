.table-flip-scroll table {
  width: 100%; }

@media only screen and (max-width: 800px) {
  .table-flip-scroll .flip-content:after,
  .table-flip-scroll .flip-header:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0; }
  .table-flip-scroll html .flip-content,
  .table-flip-scroll html .flip-header {
    -ms-zoom: 1;
    zoom: 1; }
  .table-flip-scroll *:first-child + html .flip-content,
  .table-flip-scroll *:first-child + html .flip-header {
    -ms-zoom: 1;
    zoom: 1; }
  .table-flip-scroll table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    display: block;
    position: relative; }
  .table-flip-scroll th,
  .table-flip-scroll td {
    margin: 0;
    vertical-align: top; }
  .table-flip-scroll th:last-child,
  .table-flip-scroll td:last-child {
    border-bottom: 1px solid #ddd; }
  .table-flip-scroll th {
    border: 0 !important;
    border-right: 1px solid #ddd !important;
    width: auto !important;
    display: block;
    text-align: right; }
  .table-flip-scroll td {
    display: block;
    text-align: left;
    border: 0 !important;
    border-bottom: 1px solid #ddd !important; }
  .table-flip-scroll thead {
    display: block;
    float: left; }
  .table-flip-scroll thead tr {
    display: block; }
  .table-flip-scroll tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap; }
  .table-flip-scroll tbody tr {
    display: inline-block;
    vertical-align: top;
    margin-left: -5px;
    border-left: 1px solid #ddd; } }
