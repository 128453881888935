/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.header .header-block-nav {
  margin-left: auto;
  white-space: nowrap;
  padding-right: 15px; }
  @media (max-width: 767px) {
    .header .header-block-nav {
      padding-right: 25px; } }
  .header .header-block-nav::after {
    content: "";
    display: table;
    clear: both; }
  .header .header-block-nav a {
    text-decoration: none; }
  .header .header-block-nav ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  .header .header-block-nav > ul {
    display: table; }
  .header .header-block-nav > ul > li {
    display: table-cell;
    position: relative; }
    .header .header-block-nav > ul > li:before {
      display: block;
      content: " ";
      width: 1px;
      height: 24px;
      top: 50%;
      margin-top: -12px;
      background-color: #8b9cb1;
      position: absolute;
      left: 0; }
    .header .header-block-nav > ul > li:first-child:before {
      display: none; }
  .header .header-block-nav > ul > li > a {
    padding: 0 15px;
    color: #4f5f6f; }
    .header .header-block-nav > ul > li > a:hover {
      color: #85CE36; }
  .header .header-block-nav .dropdown-menu {
    margin-top: 15px; }
