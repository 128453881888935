/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.row .col {
  padding-left: 15px;
  padding-right: 15px;
  float: left; }

.row-sm {
  margin-left: -10px;
  margin-right: -10px; }
  .row-sm [class^='col'] {
    padding-left: 10px;
    padding-right: 10px; }

.title-block {
  padding-bottom: 15px;
  margin-bottom: 30px;
  border-bottom: 1px solid #d7dde4; }
  .title-block::after {
    content: "";
    display: table;
    clear: both; }
  @media (max-width: 767px) {
    .title-block {
      margin-bottom: 20px; } }

.subtitle-block {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px dashed #e0e5ea; }

.section {
  display: block;
  margin-bottom: 15px; }
  @media (min-width: 1200px) {
    .section {
      margin-bottom: 25px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .section {
    margin-bottom: 20px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .section {
    margin-bottom: 15px; } }
  @media (max-width: 767px) {
    .section {
      margin-bottom: 10px; } }
  .section:last-of-type {
    margin-bottom: 0; }

.box-placeholder {
  margin-bottom: 15px;
  padding: 20px;
  border: 1px dashed #ddd;
  background: #fafafa;
  color: #444;
  cursor: pointer; }
