/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.alert {
  background-image: none; }
  .alert.alert-primary {
    background-color: #85CE36;
    border-color: #85CE36;
    color: #ffffff; }
    .alert.alert-primary hr {
      border-top-color: #78bd2e; }
    .alert.alert-primary .alert-link {
      color: #e6e6e6; }
  .alert.alert-success {
    background-color: #4bcf99;
    border-color: #4bcf99;
    color: #ffffff; }
    .alert.alert-success hr {
      border-top-color: #37ca8e; }
    .alert.alert-success .alert-link {
      color: #e6e6e6; }
  .alert.alert-info {
    background-color: #76D4F5;
    border-color: #76D4F5;
    color: #ffffff; }
    .alert.alert-info hr {
      border-top-color: #5ecdf3; }
    .alert.alert-info .alert-link {
      color: #e6e6e6; }
  .alert.alert-warning {
    background-color: #fe974b;
    border-color: #fe974b;
    color: #ffffff; }
    .alert.alert-warning hr {
      border-top-color: #fe8832; }
    .alert.alert-warning .alert-link {
      color: #e6e6e6; }
  .alert.alert-danger {
    background-color: #FF4444;
    border-color: #FF4444;
    color: #ffffff; }
    .alert.alert-danger hr {
      border-top-color: #ff2b2b; }
    .alert.alert-danger .alert-link {
      color: #e6e6e6; }
  .alert.alert-inverse {
    background-color: #131e26;
    border-color: #131e26;
    color: #ffffff; }
    .alert.alert-inverse hr {
      border-top-color: #0b1115; }
    .alert.alert-inverse .alert-link {
      color: #e6e6e6; }
