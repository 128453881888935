@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,800,700,600);
/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  min-height: 100%;
  font-family: 'Open Sans', sans-serif;
  color: #4f5f6f;
  overflow-x: hidden; }

.main-wrapper {
  width: 100%;
  position: absolute;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden; }

#ref .color-primary {
  color: #85CE36; }

#ref .chart .color-primary {
  color: #85CE36; }

#ref .chart .color-secondary {
  color: #9ed85f; }

.app {
  position: relative;
  width: 100%;
  padding-left: 230px;
  min-height: 100vh;
  margin: 0 auto;
  left: 0;
  background-color: #f0f3f6;
  box-shadow: 0 0 3px #ccc;
  transition: left 0.3s ease, padding-left 0.3s ease;
  overflow: hidden; }
  .app .content {
    padding: 95px 20px 75px 20px;
    min-height: 100vh; }
    @media (min-width: 1200px) {
      .app .content {
        padding: 105px 40px 85px 40px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .app .content {
    padding: 100px 35px 80px 35px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .app .content {
    padding: 95px 20px 75px 20px; } }
    @media (max-width: 767px) {
      .app .content {
        padding: 70px 20px 70px 20px; } }
  @media (max-width: 991px) {
    .app {
      padding-left: 0; } }
  @media (max-width: 991px) {
    .app.sidebar-open {
      left: 0; } }
  .app.blank {
    background-color: #667380; }
