/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
/* Let's get this party started */
::-webkit-scrollbar {
  width: 7px;
  height: 7px; }

/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 0;
  border-radius: 0; }

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0;
  border-radius: 0;
  background: #78bd2e; }

::-webkit-scrollbar-thumb:window-inactive {
  background: #85CE36; }
