/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.modal .modal-content {
  border-radius: 0; }

.modal .modal-header {
  background-color: #85CE36;
  color: #ffffff; }

.modal .modal-footer .btn {
  margin-bottom: 0; }
