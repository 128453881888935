/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.auth {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: #667380;
  overflow-x: hidden;
  overflow-y: auto; }

.auth-container {
  width: 450px;
  min-height: 330px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%); }
  .auth-container .auth-header {
    text-align: center;
    border-bottom: 1px solid #85CE36; }
  .auth-container .auth-title {
    color: #97A4B1;
    padding: 20px;
    line-height: 30px;
    font-size: 26px;
    font-weight: 600;
    margin: 0; }
  .auth-container .auth-content {
    padding: 30px 50px;
    min-height: 260px; }
  .auth-container .forgot-btn {
    line-height: 28px; }
  .auth-container .checkbox label {
    padding: 0; }
  .auth-container .checkbox a {
    vertical-align: text-top; }
  .auth-container .checkbox span {
    color: #4f5f6f; }

@media (max-width: 767px) {
  .auth-container {
    width: 100%;
    position: relative;
    left: 0;
    top: 0;
    transform: inherit;
    margin: 0;
    margin-bottom: 10px; }
    .auth-container .auth-content {
      padding: 30px 25px; } }
