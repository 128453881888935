/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.items-list-page .title-search-block {
  position: relative; }

@media (max-width: 767px) {
  .items-list-page .title-block {
    padding-bottom: 10px;
    margin-bottom: 13px; } }

.items-list-page .title-block .action {
  display: inline; }
  .items-list-page .title-block .action a {
    padding: 10px 15px; }
    .items-list-page .title-block .action a .icon {
      margin-right: 5px;
      text-align: center;
      width: 16px; }
  @media (max-width: 767px) {
    .items-list-page .title-block .action {
      display: none; } }

.items-list-page .items-search {
  position: absolute;
  margin-bottom: 15px;
  right: 0;
  top: 0; }
  @media (max-width: 767px) {
    .items-list-page .items-search {
      position: static; } }
  .items-list-page .items-search .search-button {
    margin: 0; }

.items-list-page .item-list .item-col.item-col-check {
  text-align: left; }

.items-list-page .item-list .item-col.item-col-img {
  text-align: left;
  width: auto;
  text-align: center;
  flex-basis: 70px; }
  @media (min-width: 576px) {
    .items-list-page .item-list .item-col.item-col-img:not(.item-col-header) {
      height: 80px; } }

.items-list-page .item-list .item-col.item-col-title {
  text-align: left;
  margin-left: 0 !important;
  margin-right: auto;
  flex-basis: 0;
  flex-grow: 9; }

.items-list-page .item-list .item-col.item-col-sales {
  text-align: right;
  font-weight: 600; }

.items-list-page .item-list .item-col.item-col-stats {
  text-align: center; }

.items-list-page .item-list .item-col.item-col-category {
  text-align: left;
  font-weight: 600; }

.items-list-page .item-list .item-col.item-col-author {
  text-align: left;
  flex-grow: 4.5; }

.items-list-page .item-list .item-col.item-col-date {
  text-align: right; }

@media (max-width: 767px) {
  .items-list-page .card.items {
    background: none;
    box-shadow: none; }
  .items-list-page .item-list .item {
    border: none;
    margin-bottom: 10px;
    background-color: #fff;
    box-shadow: 1px 1px 5px rgba(126, 142, 159, 0.1); }
  .items-list-page .item-list .item-row {
    padding: 0 !important; }
  .items-list-page .item-list .item-col.item-col-author {
    flex-grow: 3; } }

@media (min-width: 576px) and (max-width: 767px) {
  .items-list-page .item-list .item {
    background-color: #fff;
    margin-bottom: 10px;
    box-shadow: 1px 1px 5px rgba(126, 142, 159, 0.1); }
  .items-list-page .item-list .item-row {
    padding: 0; }
  .items-list-page .item-list .item-heading {
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    line-height: 40px;
    padding-left: 0; }
  .items-list-page .item-list .item-col.item-col-check, .items-list-page .item-list .item-col.item-col-title, .items-list-page .item-list .item-col.item-col-actions-dropdown {
    border-bottom: 1px solid #d7dde4; }
    .items-list-page .item-list .item-col.item-col-check .item-heading, .items-list-page .item-list .item-col.item-col-title .item-heading, .items-list-page .item-list .item-col.item-col-actions-dropdown .item-heading {
      display: none; }
  .items-list-page .item-list .item-col.item-col-sales, .items-list-page .item-list .item-col.item-col-stats, .items-list-page .item-list .item-col.item-col-category, .items-list-page .item-list .item-col.item-col-author, .items-list-page .item-list .item-col.item-col-date {
    padding-top: 40px;
    position: relative; }
  .items-list-page .item-list .item-col.item-col-check {
    display: none; }
  .items-list-page .item-list .item-col.item-col-title {
    padding-left: 10px;
    text-align: left;
    margin-left: 0 !important;
    margin-right: auto;
    flex-basis: calc(100% - 40px); }
  .items-list-page .item-list .item-col.item-col-img {
    padding-left: 10px;
    flex-basis: 79px; }
  .items-list-page .item-list .item-col.item-col-sales {
    text-align: left; }
  .items-list-page .item-list .item-col.item-col-stats {
    text-align: center; }
  .items-list-page .item-list .item-col.item-col-category {
    text-align: center; }
  .items-list-page .item-list .item-col.item-col-author {
    text-align: center; }
  .items-list-page .item-list .item-col.item-col-date {
    padding-right: 10px;
    text-align: right;
    white-space: nowrap;
    flex-basis: 100px;
    flex-basis: 0;
    flex-grow: 3; } }

@media (max-width: 575px) {
  .items-list-page .item-list .item {
    border: none;
    font-size: 0.9rem;
    margin-bottom: 10px;
    background-color: #fff;
    box-shadow: 1px 1px 5px rgba(126, 142, 159, 0.1); }
    .items-list-page .item-list .item .item-col {
      text-align: right;
      border-bottom: 1px solid #d7dde4;
      padding-left: 10px; }
      .items-list-page .item-list .item .item-col[class^=item-col] {
        flex-basis: 100%; }
      .items-list-page .item-list .item .item-col.item-col-check {
        display: none; }
      .items-list-page .item-list .item .item-col.item-col-img .item-img {
        padding-bottom: 65%; }
      .items-list-page .item-list .item .item-col.item-col-title {
        text-align: left;
        padding-bottom: 0;
        border: none;
        flex-grow: 1;
        flex-basis: 0; }
        .items-list-page .item-list .item .item-col.item-col-title .item-heading {
          display: none; }
        .items-list-page .item-list .item .item-col.item-col-title .item-title {
          font-size: 1rem;
          line-height: 1.4rem; }
      .items-list-page .item-list .item .item-col.item-col-actions-dropdown {
        border: none;
        padding-bottom: 0; }
      .items-list-page .item-list .item .item-col.item-col-sales {
        text-align: left; }
      .items-list-page .item-list .item .item-col.item-col-stats {
        text-align: left; }
      .items-list-page .item-list .item .item-col.item-col-category {
        text-align: left; }
      .items-list-page .item-list .item .item-col.item-col-author {
        text-align: left; }
      .items-list-page .item-list .item .item-col.item-col-date {
        text-align: left; } }
