/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.title {
  font-size: 1.45rem;
  font-weight: 600;
  margin-bottom: 0; }
  .title.l {
    font-size: 1.6rem; }
  .title.s {
    font-size: 1.4rem; }
  .card .title {
    font-size: 1.1rem;
    color: #4f5f6f; }

.title-description {
  margin: 0;
  font-size: 0.9rem;
  font-weight: normal;
  color: #7e8e9f; }
  .title-description.s {
    font-size: 0.8rem; }
  @media (max-width: 767px) {
    .title-description {
      display: none; } }

.subtitle {
  font-size: 1.2rem;
  margin: 0;
  color: #7e8e9f; }

.text-primary {
  color: #85CE36 !important; }

.text-muted {
  color: #9ba8b5; }

pre {
  padding: 0;
  border: none;
  background: none; }
