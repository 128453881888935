/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.header .header-block-nav .profile .img {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  background-color: #8b9cb1;
  color: #ffffff;
  text-align: center;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  vertical-align: middle; }

.header .header-block-nav .profile .name {
  display: inline-block;
  margin-right: 9px;
  font-weight: bold; }
  @media (max-width: 767px) {
    .header .header-block-nav .profile .name {
      display: none; } }

.header .header-block-nav .profile .arrow {
  color: #85CE36; }

.header .header-block-nav .profile-dropdown-menu {
  left: auto;
  right: 0;
  min-width: 180px;
  white-space: normal; }
  .header .header-block-nav .profile-dropdown-menu:before {
    position: absolute;
    right: 10px;
    bottom: 100%;
    margin-right: -1px; }
  .header .header-block-nav .profile-dropdown-menu:after {
    position: absolute;
    right: 10px;
    bottom: 100%; }
  .header .header-block-nav .profile-dropdown-menu a {
    padding: 10px 15px; }
    .header .header-block-nav .profile-dropdown-menu a .icon {
      color: #85CE36;
      text-align: center;
      width: 16px; }
    .header .header-block-nav .profile-dropdown-menu a span {
      display: inline-block;
      padding-left: 5px;
      text-align: left;
      color: #7e8e9f; }
  .header .header-block-nav .profile-dropdown-menu .profile-dropdown-menu-icon {
    padding: 0; }
  .header .header-block-nav .profile-dropdown-menu .profile-dropdown-menu-topic {
    color: #7e8e9f;
    padding: 0; }
  .header .header-block-nav .profile-dropdown-menu .dropdown-divider {
    margin: 0; }
  .header .header-block-nav .profile-dropdown-menu .logout {
    border-top: 1px solid rgba(126, 142, 159, 0.1); }
  @media (max-width: 767px) {
    .header .header-block-nav .profile-dropdown-menu {
      margin-top: 8px; } }
