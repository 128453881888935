/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
#modal-media .modal-body {
  min-height: 250px; }

#modal-media .modal-tab-content {
  min-height: 300px; }

#modal-media .images-container {
  padding: 15px;
  text-align: center; }
  #modal-media .images-container .image-container {
    margin: 0 auto 10px auto;
    cursor: pointer;
    transition: all 0.3s ease;
    display: inline-block;
    float: none; }
    #modal-media .images-container .image-container:hover {
      border-color: rgba(133, 206, 54, 0.5); }
    #modal-media .images-container .image-container.active {
      border-color: rgba(133, 206, 54, 0.5); }

#modal-media .upload-container {
  padding: 15px; }
  #modal-media .upload-container .dropzone {
    position: relative;
    border: 2px dashed #85CE36;
    height: 270px; }
    #modal-media .upload-container .dropzone .dz-message-block {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%); }
      #modal-media .upload-container .dropzone .dz-message-block .dz-message {
        margin: 0;
        font-size: 24px;
        color: #85CE36;
        width: 230px; }
