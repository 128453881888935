a:focus {
	outline: none;
}

.notification {
	position: absolute;
	z-index: 9999;
	top: 0;
	left: 0;
	width: 100%;
	border-radius: 0;
	text-align: center;
	transition: opacity .25s ease-in-out;
}

.notification:hover {
	cursor: pointer;
	opacity: .7;
}

.nav.nav-tabs-bordered + .tab-content {
	padding-bottom: 20px !important;
}

.blur {
	-webkit-filter: blur(3px);
	filter: blur(3px);
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.has-error {
	color: #F44;
	font-size: 13px;
	display: block !important;
}

.tab-with-error {
	color: red !important;
	font-weight: bold;
}

.item-col-header {
	outline: solid 1px;
}

.full-width {
	width: 100%;
}

.content-overview.item-list {
	display: table;
}

.table.content-overview td {
	vertical-align: middle !important;
}

.content-overview .with-placeholder {
	max-width: 3em;
}

.content-overview .placeholder {
	max-height: 3em;
}

.item-list .item-actions-dropdown.active .item-actions-block {
	max-width: 300px !important;
}

img {
	max-width: 100%;
}

.file-preview .preview {
	height: 4em;
	line-height: 5em;
	overflow: hidden;
}

.file-preview .preview.directory {
	height: auto;
}

.users-list ul {
	margin-left: -30px;
}

.users-list img {
	border-radius: 50%;
	padding: 3px;
}

.timeline {
	list-style: none;
	padding: 20px 0 20px;
	position: relative;
}

.timeline:before {
	top: 0;
	bottom: 0;
	position: absolute;
	content: " ";
	width: 3px;
	background-color: #EEE;
	left: 50%;
	margin-left: -1.5px;
}

.timeline > li {
	position: relative;
}

.timeline > li:before,
.timeline > li:after {
	content: " ";
	display: table;
}

.timeline > li:after {
	clear: both;
}

.timeline > li > .timeline-panel {
	width: 46%;
	float: left;
	border-radius: 2px;
	padding: 10px;
	position: relative;
	-webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
	border: 1px solid #D4D4D4;
	box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
	background: #FFF;
}

.timeline > li > .timeline-panel:before {
	position: absolute;
	top: 26px;
	right: -15px;
	display: inline-block;
	border-top: 15px solid transparent;
	border-left: 15px solid #CCC;
	border-right: 0 solid #CCC;
	border-bottom: 15px solid transparent;
	content: " ";
}

.timeline > li > .timeline-panel:after {
	position: absolute;
	top: 27px;
	right: -14px;
	display: inline-block;
	border-top: 14px solid transparent;
	border-left: 14px solid #FFF;
	border-right: 0 solid #FFF;
	border-bottom: 14px solid transparent;
	content: " ";
}

.timeline > li > .timeline-badge {
	color: #FFF;
	width: 30px;
	height: 30px;
	text-align: center;
	position: absolute;
	top: 26px;
	left: 50%;
	margin-left: -14px;
	background-color: #DDD;
	z-index: 100;
	border-radius: 50%;
}

.timeline > li > .timeline-badge.big {
	width: 40px;
	height: 40px;
	top: 23px;
	margin-left: -20px;
}

.timeline:not(.more-space) > li {
	margin-top: -25px;
}

.timeline > li:first-child {
	margin-top: 0;
}

.timeline > li.timeline-inverted > .timeline-panel {
	float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
	border-left-width: 0;
	border-right-width: 15px;
	left: -15px;
	right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
	border-left-width: 0;
	border-right-width: 14px;
	left: -14px;
	right: auto;
}

.timeline-title {
	margin-top: 0;
	color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
	margin-bottom: 0;
}

.timeline-body > p + p {
	margin-top: 5px;
}

@media (max-width: 767px) {
	ul.timeline:before {
		left: 40px;
	}

	ul.timeline > li > .timeline-panel {
		width: calc(100% - 90px);
		width: -moz-calc(100% - 90px);
		width: -webkit-calc(100% - 90px);
	}

	ul.timeline > li > .timeline-badge {
		left: 15px;
		margin-left: 0;
		top: 16px;
	}

	ul.timeline > li > .timeline-panel {
		float: right;
	}

	ul.timeline > li > .timeline-panel:before {
		border-left-width: 0;
		border-right-width: 15px;
		left: -15px;
		right: auto;
	}

	ul.timeline > li > .timeline-panel:after {
		border-left-width: 0;
		border-right-width: 14px;
		left: -14px;
		right: auto;
	}
}

.sidebar .divider {
	height: 10px;
}

.sidebar .nav > li > ul > li a {
	padding-left: 30px !important;
}

.ace-editor-container {
	position: relative;
}

.ace-editor {
	position: absolute !important;
	width: 100%;
	height: 100%;
	bottom: 0;
}

.pagination {
	list-style: none;
}

.pagination li {
	display: inline;
	padding: .5em;
}

.header-block img {
	margin-right: 10px;
}

.truncate {
	word-break: break-all;
}

.bold {
	font-weight: bold;
}

.m-l-10 {
	margin-left: 10px;
}

.m-t-15 {
	margin-top: 15px;
}

.p-tb-15 {
	padding-top: 15px;
	padding-bottom: 15px;
}

.card .card-header .title {
	line-height: 1.5em;
}

.card .card-header .title small {
	padding-left: 10px;
}

.card.file-preview .card-block {
	padding: 0;
	height: 6em;
	overflow: hidden;
}

.card.file-preview .card-block p {
	padding: 1em 5px;
}

.card.file-preview .card-footer {
	padding: 5px !important;
}

.card.file-preview .card-block img {
	width: 100%;
}

.has-subpages:hover {
	cursor: pointer;
}

#orderSetup .subpage td:first-child {
	padding-left: 35px;
}

