/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.sidebar {
  background-color: #3a4651;
  width: 230px;
  padding-bottom: 60px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transition: left 0.3s ease;
  z-index: 20; }
  @media (max-width: 991px) {
    .sidebar {
      position: fixed;
      left: -230px; } }
  .sidebar-fixed .sidebar {
    position: fixed; }
  .sidebar-open .sidebar {
    left: 0; }
  .sidebar .sidebar-container {
    position: absolute;
    top: 0;
    bottom: 51px;
    width: 100%;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden; }
    .sidebar .sidebar-container::-webkit-scrollbar-track {
      background-color: #2c353e; }
  .sidebar .nav {
    font-size: 14px; }
    .sidebar .nav li a:focus,
    .open .sidebar .nav li a:focus {
      background-color: inherit; }
    .sidebar .nav ul {
      padding: 0;
      height: 0px;
      overflow: hidden; }
      .loaded .sidebar .nav ul {
        height: auto; }
    .sidebar .nav li.active ul {
      height: auto; }
    .sidebar .nav li a {
      color: rgba(255, 255, 255, 0.5);
      text-decoration: none; }
    .sidebar .nav li a:hover,
    .sidebar .nav li.open > a,
    .sidebar .nav li.open a:hover {
      color: #ffffff;
      background-color: #2d363f; }
    .sidebar .nav > li > a {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 20px;
      padding-right: 10px; }
    .sidebar .nav > li.active > a,
    .sidebar .nav > li.active > a:hover {
      background-color: #85CE36 !important;
      color: #ffffff !important; }
    .sidebar .nav > li.open > a {
      background-color: #333e48; }
    .sidebar .nav > li.open > a i.arrow {
      transform: rotate(90deg); }
    .sidebar .nav > li > a i {
      margin-right: 5px;
      font-size: 16px; }
      .sidebar .nav > li > a i.arrow {
        float: right;
        font-size: 20px;
        line-height: initial;
        transition: all 0.3s ease; }
        .sidebar .nav > li > a i.arrow:before {
          content: "\f105" !important; }
    .sidebar .nav > li > ul > li a {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 50px;
      background-color: #333e48; }
    .sidebar .nav > li > ul > li.active a {
      color: #ffffff; }

.sidebar-overlay {
  position: absolute;
  display: none;
  left: 200vw;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 15; }
  @media (max-width: 991px) {
    .sidebar-overlay {
      display: block; } }
  @media (max-width: 767px) {
    .sidebar-overlay {
      background-color: rgba(0, 0, 0, 0.7); } }
  @media (max-width: 991px) {
    .sidebar-open .sidebar-overlay {
      left: 0;
      opacity: 1; } }
