/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.modal-body.modal-tab-container {
  padding: 0; }
  .modal-body.modal-tab-container .modal-tabs {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3); }
    .modal-body.modal-tab-container .modal-tabs .nav-link {
      padding: 10px 20px;
      border: none; }
      .modal-body.modal-tab-container .modal-tabs .nav-link:hover, .modal-body.modal-tab-container .modal-tabs .nav-link.active {
        color: #85CE36;
        border-bottom: 2px solid #85CE36; }
      .modal-body.modal-tab-container .modal-tabs .nav-link.active {
        font-weight: 600; }
