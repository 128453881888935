/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.underline-animation {
  position: absolute;
  top: auto;
  bottom: 1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #85CE36;
  content: '';
  transition: all 0.2s;
  backface-visibility: hidden;
  transform: scaleX(0); }

.stat-chart {
  border-radius: 50%; }

.stat {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin-right: 10px; }
  .stat .value {
    font-size: 20px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500; }
  .stat .name {
    overflow: hidden;
    text-overflow: ellipsis; }
  .stat.lg .value {
    font-size: 26px;
    line-height: 28px; }
  .stat.lg .name {
    font-size: 16px; }

.list-icon [class^='col'] {
  cursor: pointer; }
  .list-icon [class^='col'] em {
    font-size: 14px;
    width: 40px;
    vertical-align: middle;
    margin: 0;
    display: inline-block;
    text-align: center;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    line-height: 30px; }
  .list-icon [class^='col']:hover em {
    transform: scale(2, 2); }

.well {
  background-image: none;
  background-color: #fff; }

.jumbotron {
  background-image: none;
  background-color: #fff;
  padding: 15px 30px; }
  .jumbotron.jumbotron-fluid {
    padding-left: 0;
    padding-right: 0; }

.rounded {
  border-radius: 0.25rem; }

.rounded-l {
  border-radius: 0.3rem; }

.rounded-s {
  border-radius: 0.2rem; }

.jqstooltip {
  height: 25px !important;
  width: auto !important;
  border-radius: 0.2rem; }
