/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.header .header-block-buttons {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap; }
  .header .header-block-buttons .btn.header-btn {
    background-color: transparent;
    border: 1px solid #64798d;
    color: #64798d;
    margin: 0 5px;
    border-radius: 0.2rem; }
    .header .header-block-buttons .btn.header-btn:hover, .header .header-block-buttons .btn.header-btn:focus {
      border: 1px solid #3a4651;
      color: #3a4651; }
    @media (max-width: 767px) {
      .header .header-block-buttons .btn.header-btn span {
        display: none; } }
