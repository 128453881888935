/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
a:not(.btn) {
  transition: initial;
  text-decoration: underline;
  color: #969696; }
  a:not(.btn):hover {
    text-decoration: underline;
    color: #70b02b; }
    a:not(.btn):hover:before {
      transform: scaleX(1); }
  a:not(.btn):focus {
    text-decoration: none; }

span a {
  vertical-align: text-bottom; }

[class^='nav'] li > a,
[class*=' nav'] li > a {
  display: block; }
  [class^='nav'] li > a:before,
  [class*=' nav'] li > a:before {
    display: none; }

.nav.nav-tabs-bordered {
  border-color: #85CE36; }
  .nav.nav-tabs-bordered + .tab-content {
    border-style: solid;
    border-width: 0 1px 1px 1px;
    border-color: #85CE36;
    padding: 10px 20px 0; }
  .nav.nav-tabs-bordered .nav-item .nav-link {
    text-decoration: none; }
    .nav.nav-tabs-bordered .nav-item .nav-link:hover {
      color: #ffffff;
      background-color: #85CE36;
      border: 1px solid #85CE36; }
    .nav.nav-tabs-bordered .nav-item .nav-link.active {
      border-color: #85CE36;
      border-bottom-color: transparent; }
      .nav.nav-tabs-bordered .nav-item .nav-link.active:hover {
        background-color: #ffffff;
        color: inherit; }

.nav.nav-pills + .tab-content {
  border: 0;
  padding: 5px; }

.nav.nav-pills .nav-item .nav-link {
  text-decoration: none; }
  .nav.nav-pills .nav-item .nav-link:hover {
    color: #4f5f6f;
    background-color: transparent;
    border: 0; }
  .nav.nav-pills .nav-item .nav-link.active {
    border-color: #85CE36;
    border-bottom-color: transparent;
    background-color: #85CE36; }
    .nav.nav-pills .nav-item .nav-link.active:hover {
      background-color: #85CE36;
      color: #ffffff; }
