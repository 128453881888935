/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.customize {
  width: 100%;
  color: rgba(255, 255, 255, 0.5);
  padding: 5px 15px;
  text-align: center; }
  .customize .customize-header {
    margin-bottom: 10px; }

#customize-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 230px; }
  @media (max-width: 991px) {
    .sidebar-open #customize-menu {
      left: 0; } }
  @media (max-width: 991px) {
    #customize-menu {
      transition: left 0.3s ease;
      left: -230px; } }
  #customize-menu > li > a {
    background-color: #3a4651;
    border-top: 1px solid rgba(45, 54, 63, 0.5); }
  #customize-menu > li > a:hover,
  #customize-menu > li.open > a {
    background-color: #2d363f; }
  #customize-menu .customize {
    width: 230px;
    color: rgba(255, 255, 255, 0.5);
    background-color: #2d363f;
    text-align: center;
    padding: 10px 15px;
    border-top: 2px solid #85CE36; }
    #customize-menu .customize .customize-item {
      margin-bottom: 15px; }
      #customize-menu .customize .customize-item .customize-header {
        margin-bottom: 10px; }
      #customize-menu .customize .customize-item label {
        font-weight: 400; }
        #customize-menu .customize .customize-item label.title {
          font-size: 14px; }
      #customize-menu .customize .customize-item .radio + span {
        padding: 0;
        padding-left: 5px; }
      #customize-menu .customize .customize-item .radio + span:before {
        font-size: 17px;
        color: #546273;
        cursor: pointer; }
      #customize-menu .customize .customize-item .radio:checked + span:before {
        color: #85CE36; }
      #customize-menu .customize .customize-item .customize-colors {
        list-style: none; }
        #customize-menu .customize .customize-item .customize-colors li {
          display: inline-block;
          margin-left: 5px;
          margin-right: 5px; }
          #customize-menu .customize .customize-item .customize-colors li .color-item {
            display: block;
            height: 20px;
            width: 20px;
            border: 1px solid;
            cursor: pointer; }
            #customize-menu .customize .customize-item .customize-colors li .color-item.color-red {
              background-color: #FB494D;
              border-color: #FB494D; }
            #customize-menu .customize .customize-item .customize-colors li .color-item.color-orange {
              background-color: #FE7A0E;
              border-color: #FE7A0E; }
            #customize-menu .customize .customize-item .customize-colors li .color-item.color-green {
              background-color: #8CDE33;
              border-color: #8CDE33; }
            #customize-menu .customize .customize-item .customize-colors li .color-item.color-seagreen {
              background-color: #4bcf99;
              border-color: #4bcf99; }
            #customize-menu .customize .customize-item .customize-colors li .color-item.color-blue {
              background-color: #52BCD3;
              border-color: #52BCD3; }
            #customize-menu .customize .customize-item .customize-colors li .color-item.color-purple {
              background-color: #7867A7;
              border-color: #7867A7; }
            #customize-menu .customize .customize-item .customize-colors li .color-item.active {
              position: relative;
              font-family: FontAwesome;
              font-size: 17px;
              line-height: 17px; }
              #customize-menu .customize .customize-item .customize-colors li .color-item.active:before {
                content: "\f00c";
                position: absolute;
                top: 0;
                left: 0;
                color: #fff; }
