/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.dropdown-menu {
  float: left;
  box-shadow: 2px 3px 6px rgba(126, 142, 159, 0.1);
  border: 1px solid rgba(126, 142, 159, 0.1);
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .dropdown-menu .dropdown-item {
    display: block;
    padding: 0px 15px;
    clear: both;
    font-weight: normal;
    color: #4f5f6f;
    white-space: nowrap;
    transition: none; }
    .dropdown-menu .dropdown-item i {
      margin-right: 2px; }
    .dropdown-menu .dropdown-item:hover {
      color: #85CE36 !important;
      background: none;
      background-color: whitesmoke; }
